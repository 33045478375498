























































































































import Vue from "vue";
import Component from "vue-class-component";
import { getModule } from "vuex-module-decorators";
import tradeshowVuexModule from "@/store/vuex-modules/getTradeshowData";
import PageTitleHeader from "@/components/shared/PageTitleHeader.vue";
import Spinners from "@/components/utilities/Spinners.vue";

const tradeshowStore = getModule(tradeshowVuexModule);

@Component({
    components: {
        Spinners,
        "page-title-header": PageTitleHeader
    },
    filters: {
        maxCharacter(value: string) {
            return value.slice(0, 250) + "...";
        }
    }
})
export default class TwoTiersSponsorsWithDescription extends Vue {
    isLoading = true;

    get titleCompanies() {
        const companies = tradeshowStore.tradeshowCompanies.title;
        return Vue.prototype.MgSortByProperty(companies, "companyName");
    }
    get premierCompanies() {
        const companies = tradeshowStore.tradeshowCompanies.premier;
        return Vue.prototype.MgSortByProperty(companies, "companyName");
    }

    get cssVariables() {
        return this.$store.getters.cssVariables;
    }
    get pageConfig() {
        return this.$store.getters.getPageOptions("tradeshow");
    }

    get pageDecorImage(): string {
        let returnValue = "";
        if (
            this.pageConfig.decorImages &&
            this.pageConfig.decorImages.pageBackground
        ) {
            returnValue = `${process.env.BASE_URL}bg/${this.pageConfig.decorImages.pageBackground}`;
        }
        return returnValue;
    }

    async created() {
        await tradeshowStore
            .getTradeshowCompanies()
            .finally(() => (this.isLoading = false));
    }
}
